import React from 'react'
import Layout from '../components/Layout'
import * as styles from '../assets/css/privacyterms.module.css'
import {graphql} from 'gatsby'
import {Trans} from 'gatsby-plugin-react-i18next'

export default function PrivacyTerms() {
  return (
    <div className={styles.privacyterms}>
      <Layout>
        <div className="privacy-termsmain-wraper">
          <div className="privacy-termsmain-wraped">
            <div className="subtitle-descrription-wraper">
              <h2 className="subtitle">
                <Trans>Privacy Policy</Trans>
              </h2>
              <p><Trans>Talking Yak English Learning Private Limited built the Talking Yak app as a Commercial app. This
                SERVICE is provided by Talking Yak English Learning Private Limited and is intended for use as is. This
                page is used to inform visitors regarding our policies with the collection, use, and disclosure of
                Personal Information if anyone decided to use our Service.</Trans></p>
              <p><Trans>If you choose to use our Service, then you agree to the collection and use of information in
                relation to this policy. The Personal Information that we collect is used for providing and improving
                the Service. We will not use or share your information with anyone except as described in this Privacy
                Policy.</Trans></p>
              <p><Trans>The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions,
                which are accessible at Talking Yak unless otherwise defined in this Privacy Policy.</Trans></p>
              <div className="sub-subtitle-description">
                <h4 className="subtitle-wraped"><Trans>1. Information Collection and Use</Trans></h4>
                <div className="descptionwraper">
                  <p><Trans>For a better experience, while using our Service, we may require you to provide us with certain personally identifiable
                    information, including but not limited to Name, Phone Number, Email Address, Date of Birth. The information that we
                    request will be retained by us and used as described in this privacy policy.</Trans></p>
                  <p><Trans>The app does use third-party services that may collect information used to identify you. Link to the privacy policy of third-party service providers used by the app</Trans></p>
                  <ul>
                    <li><a href={"https://www.google.com/policies/privacy/"}>Google Play Services</a></li>
                    <li><a href={"https://firebase.google.com/policies/analytics"}>Google Analytics for Firebase</a></li>
                    <li><a href={"https://firebase.google.com/support/privacy/"}>Firebase Crashlytics</a></li>
                    <li><a href={"https://clevertap.com/privacy-policy/"}>Clevertap</a></li>

                  </ul>
                </div>
              </div>
              <div className="sub-subtitle-description">
                <h4 className="subtitle-wraped"><Trans>2. Log Data</Trans></h4>
                <div className="descptionwraper">
                  <Trans>We want to inform you that whenever you use our Service, in the case of an error in the app we collect data and
                    information (through third-party products) on your phone called Log Data. This Log Data may include information such as
                    your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when
                    utilizing our Service, the time and date of your use of the Service, and other statistics.</Trans>
                </div>
              </div>
              <div className="sub-subtitle-description">
                <h4 className="subtitle-wraped"><Trans>3. Cookies</Trans></h4>
                <p><Trans>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to
                  your browser from the websites that you visit and are stored on your device's internal memory.</Trans></p>
                <p><Trans>This Service does not use these “cookies” explicitly. However, the app may use third-party code and libraries that use
                  “cookies” to collect information and improve their services. You have the option to either accept or refuse these
                  cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to
                  use some portions of this Service.</Trans></p>
              </div>
              <div className="sub-subtitle-description">
                <h4 className="subtitle-wraped"><Trans>4. Service Providers</Trans></h4>
                  <p><Trans>We may employ third-party companies and individuals due to the following reasons:</Trans></p>
                  <ul>
                    <li>To facilitate our Service;
                    </li>
                    <li>To provide the Service on our behalf;</li>
                    <li>To perform Service-related services; or
                    </li>
                    <li>To assist us in analyzing how ourservice is used.</li>
                    <li>We want to inform users of this Service that these third parties have access to their Personal Information. The reason
                      is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the
                      information for any other purpose.
                    </li>
                  </ul>
              </div>
              <div className="sub-subtitle-description">
                <h4 className="subtitle-wraped"><Trans>5. Security</Trans></h4>
                <div className="descptionwraper">
                  <p><Trans>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means
                    of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100%
                    secure and reliable, and we cannot guarantee its absolute security.</Trans></p>
                </div>
              </div>
              <div className="sub-subtitle-description">
                <h4 className="subtitle-wraped"><Trans>6. Links to Other Sites</Trans></h4>
                <div className="descptionwraper">
                  <p><Trans>This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site.
                    Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of
                    these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of
                    any third-party sites or services.</Trans></p>
                </div>
              </div>
              <div className="sub-subtitle-description">
                <h4 className="subtitle-wraped"><Trans>7. Children’s Privacy</Trans></h4>
                <div className="descptionwraper">
                  <p><Trans>These Services are provided for users of all age groups. The Service may or may not be used with assistance of a parent
                    or a guardian.</Trans></p>
                </div>
              </div>
              <div className="sub-subtitle-description">
                <h4 className="subtitle-wraped"><Trans>8. Changes to This Privacy Policy</Trans></h4>
                <div className="descptionwraper">
                  <p><Trans>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any
                    changes. We will notify you of any changes by posting the new Privacy Policy on this page.</Trans></p>
                  <p><Trans>This policy is effective as of 2019-01-01</Trans></p>
                </div>
              </div>
              <div className="sub-subtitle-description">
                <h4 className="subtitle-wraped"><Trans>9. Contact Us</Trans></h4>
                <div className="descptionwraper">
                  <p><Trans>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at
                    support@talkingyak.com.</Trans></p>
                </div>
              </div>
            </div>

            <div className="subtitle-descrription-wraper">
              <h2 className="subtitle">
                <Trans>Terms & Conditions of Service</Trans>
              </h2>
              <div className="sub-subtitle-description">
                <h4 className="subtitle-wraped"><Trans>1. General</Trans></h4>
                <div className="descptionwraper">
                  <p><Trans>Talking Yak websites (“Websites”) and mobile applications and related services (together
                    with the Websites, the “Service”) are operated by Talking Yak Pvt Ltd on behalf of itself and its
                    parent company, Talking Yak, Inc. (“Talking Yak,” “us,” or “we”). Access and use of the Service is
                    subject to the following Terms and Conditions of Service (“Terms and Conditions”). By accessing or
                    using any part of the Service, you represent that you have read, understood, and agree to be bound
                    by these Terms and Conditions including any future modifications. Talking Yak may amend, update or
                    change these Terms and Conditions. If we do this, we will post a notice that we have made changes to
                    these Terms and Conditions on the Websites for at least 7 days after the changes are posted and will
                    indicate at the bottom of the Terms and Conditions the date these terms were last revised. Any
                    revisions to these Terms and Conditions will become effective the earlier of (i) the end of such
                    7-day period or (ii) the first time you access or use the Service after such changes. If you do not
                    agree to abide by these Terms and Conditions, you are not authorized to use, access or participate
                    in the Service.</Trans></p>
                  <p><Trans>PLEASE NOTE THAT THESE TERMS AND CONDITIONS CONTAIN A MANDATORY ARBITRATION OF DISPUTES
                    PROVISION THAT REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES IN CERTAIN
                    CIRCUMSTANCES, RATHER THAN JURY TRIALS OR CLASS ACTION LAWSUITS.</Trans></p>
                </div>
              </div>
              <div className="sub-subtitle-description">
                <h4 className="subtitle-wraped"><Trans>2. Description Of Website And Service</Trans></h4>
                <div className="descptionwraper">
                  <p><Trans>The Service allows users to access and use a variety of educational services, including but not
                    limited to learning or practicing a language, taking examinations, creating and viewing flashcards.
                    Talking Yak may, in its sole discretion and at any time, update, change, suspend, make improvements
                    to or discontinue any aspect of the Service, temporarily or permanently.</Trans></p>
                </div>
              </div>
              <div className="sub-subtitle-description">
                <h4 className="subtitle-wraped"><Trans>3. Registration & Submission Of Content</Trans></h4>
                <div className="descptionwraper">
                  <p><span className="colored-subtitlewraper"><Trans>a. Registration</Trans></span><Trans>In connection
                    with registering for and using the Service, you agree (i) to provide accurate, current and complete
                    information about you and/or your organization as requested by Talking Yak; (ii) to maintain the
                    confidentiality of your password and other information related to the security of your account;
                    (iii) to maintain and promptly update any registration information you provide to Talking Yak, to
                    keep such information accurate, current and complete; and (iv) to be fully responsible for all use
                    of your account and for any actions that take place through your account.</Trans></p>
                </div>
                <div className="descptionwraper">
                  <p><span className="colored-subtitlewraper"><Trans>b. Submission of Content</Trans></span> <Trans>As a
                    condition of submitting any ratings, reviews, information, data, text, photographs, audio clips,
                    audiovisual works, translations, flashcards or other materials on the Services (“Content”), you
                    hereby grant to Talking Yak a royalty free, perpetual, irrevocable, worldwide, nonexclusive,
                    transferable, and sublicensable license to use, reproduce, copy, adapt, modify, merge, distribute,
                    publicly display, create derivative works from, incorporate such Content into other works;
                    sublicense through multiple tiers the Content, and acknowledge that this license cannot be
                    terminated by you once your Content is submitted to the Services. You represent that you own or have
                    secured all legal rights necessary for the Content submitted by you to be used by you, Talking Yak,
                    and others as described and otherwise contemplated in these Terms and Conditions. You understand
                    that other users will have access to the Content and that neither they or Talking Yak have any
                    obligation to you or anyone else to maintain the confidentiality of the Content.</Trans></p>
                </div>
              </div>
              <div className="sub-subtitle-description">
                <h4 className="subtitle-wraped"><Trans>4. Your Representations And Warranties</Trans></h4>
                <div className="descptionwraper">
                  <Trans>You represent and warrant to Talking Yak that your access and use of the Service will be in
                    accordance with these Terms and Conditions and with all applicable laws, rules and regulations of
                    India, the United States and any other relevant jurisdiction, including those regarding online
                    conduct or acceptable content, and those regarding the transmission of data or information exported
                    from the United States or India and/or the jurisdiction in which you reside. You further represent
                    and warrant that you have created or own any material you submit via the Service (including
                    Translation Materials, Course Contributor Materials, Activity Materials, and Content) and that you
                    have the right, as applicable, to grant us a license to use that material as set forth above or the
                    right to assign that material to us as set forth below. You further represent and warrant that you
                    will carefully safeguard your password, user name, and account information, will not share your
                    password or user name with any third party, and will not allow any third party to access your
                    Talking Yak account for any purpose. Should there be a conflict between the governing laws of the
                    jurisdictions, the laws of the United States shall prevail.</Trans>
                </div>
              </div>
              <div className="sub-subtitle-description">
                <h4 className="subtitle-wraped"><Trans>5. Inappropriate Use</Trans></h4>
                <div className="descptionwraper">
                  <Trans>You will not upload, display or otherwise provide on or through the Service any content that:
                    (i) is libelous, defamatory, abusive, threatening, harassing, hateful, offensive or otherwise
                    violates any law or infringes upon the right of any third party (including copyright, trademark,
                    privacy, publicity or other personal or proprietary rights); or (ii) in Talking Yak’s sole judgment,
                    is objectionable or which restricts or inhibits any other person from using the Service or which may
                    expose Talking Yak or its users to any harm or liability of any kind. You will not use the Talking
                    Yak to collect information about our testing process or to develop strategy, guides or other testing
                    preparation material or a similar testing service, and you will not violate any rules applicable to
                    the Talking Yak product or otherwise cheat or circumvent the applicable procedures when taking an
                    assessment.</Trans>
                </div>
              </div>
              <div className="sub-subtitle-description">
                <h4 className="subtitle-wraped"><Trans>6. Talking Yak Products</Trans></h4>
                <div className="descptionwraper">
                  <ul>
                    <li>Talking Yak Freemium: Talking Yak Freemium is a product that is available to all users without charge. While utilizing
                      the app, users of this product might run into ads hosted by Google Admob. You acknowledge that you will see these ads
                      when using Talking Yak Freemium.
                    </li>
                    <li>Talking Yak Plus: Talking Yak Plus is a service that is designed exclusively for business consumers. There will also
                      be ads hosted by Google Admob displayed to Talking Yak Plus users. It is important to note that these ads will be part
                      of the user experience.</li>
                    <li>Talking Yak Premium/Enterprise : Talking Yak Premium/Enterprise is a premium product without any ads. Users of this
                      product will enjoy an ad-free learning experience. For premium users, this product might have additional terms and
                      conditions.
                    </li>
                  </ul>
                  <Trans>By using Talking Yak products, you agree to abide by the terms specified for each product.</Trans>
                </div>
              </div>
              <div className="sub-subtitle-description">
                <h4 className="subtitle-wraped"><Trans>7. Indemnification Of Talking Yak</Trans></h4>
                <div className="descptionwraper">
                  <Trans>You agree to defend, indemnify and hold harmless Talking Yak and its directors, officers,
                    employees, contractors, agents, suppliers, licensors, successors, parent company and assigns, from
                    and against any and all losses, claims, causes of action, obligations, liabilities and damages
                    whatsoever, including attorneys’ fees, arising out of or relating to your access or use of the
                    Service, any false representation made to us (as part of these Terms and Conditions or otherwise),
                    your breach of any of these Terms and Conditions, or any claim that any translation we provide to
                    you is inaccurate, inappropriate or defective in any way whatsoever.</Trans>
                </div>
              </div>
              <div className="sub-subtitle-description">
                <h4 className="subtitle-wraped"><Trans>8. License To Apps</Trans></h4>
                <div className="descptionwraper">
                  <Trans>Subject to the terms of these Terms and Conditions and necessary user registration, Talking Yak
                    grants you a non-transferable, non-exclusive license to download, install, and use one copy of each
                    App in object code form only on an interactive wireless device that you own or control. You may not
                    derive or attempt to derive the source code of all or any portion of any App, permit any third party
                    to derive or attempt to derive such source code, or reverse engineer, decompile, disassemble, or
                    translate any App or any part thereof. Talking Yak and its licensors own and shall retain all
                    intellectual property rights and other rights in and to the Apps, and any changes, modifications, or
                    corrections thereto.</Trans>
                </div>
              </div>
              <div className="sub-subtitle-description">
                <h4 className="subtitle-wraped"><Trans>9. In-App Purchases</Trans></h4>
                <div className="descptionwraper">
                  <p>If you purchase an auto-renewing periodic subscription through the Service, your Talking Yak
                    account will be billed continuously for the subscription until you terminate it as set forth below.
                    After your initial subscription period, and again after any subsequent subscription period, your
                    subscription will automatically renew for an additional equivalent period. If you do not wish your
                    subscription to renew automatically, or if you want to change or terminate your subscription, you
                    will need to log in to your Talking Yak account and follow instructions to terminate or change your
                    subscription, even if you have deleted your account.</p> <p>In the Service, you may purchase, with
                  “real world” money, a limited, personal, non-transferable, non-sublicensable, revocable license to use
                  (a) “virtual currency,” including but not limited to virtual gems, solely for use in the Service, and
                  (b) “virtual in-app items” (together with “virtual currency,” “Virtual Items”). You are allowed to
                  purchase Virtual Items through the Service, and not in any other way.</p><p> Talking Yak may manage,
                  regulate, control, modify, or eliminate Virtual Items at any time, with or without notice. Talking Yak
                  may update the pricing of Virtual Items at any time in its sole discretion, and may add new Virtual
                  Items for additional fees. Talking Yak shall have no liability to you or any third party</p><p>in the
                  event that Talking Yak exercises any such rights. The transfer of Virtual Items is prohibited except
                  where expressly authorized in the Service. Other than as expressly authorized in the Service, you
                  shall not sell, redeem or otherwise transfer Virtual Items to any person or entity, including but not
                  limited to Company, another user, or any third party. You agree to pay all fees and applicable taxes
                  incurred by you or anyone using a Talking Yak account registered to you. Talking Yak may revise the
                  pricing for the goods and services offered through the Service from time to time. All information that
                  you provide in connection with a purchase or transaction or other monetary transaction interaction
                  with the Service must be accurate, complete, and current. You agree to pay all charges incurred by
                  users of your credit card, debit card, or other payment method used in connection with a purchase or
                  transaction or other monetary transaction interaction with the Service at the prices in effect when
                  such charges are incurred. You will pay any applicable taxes, if any, relating to any such purchases,
                  transactions or other monetary transaction interactions.</p>
                </div>
              </div>
              <div className="sub-subtitle-description">
                <h4 className="subtitle-wraped"><Trans>10. Payment Processors</Trans></h4>
                <div className="descptionwraper">
                  <Trans>All financial transactions made in connection with the Service will be processed by a third
                    party in accordance with their respective terms of use, privacy policy, and/or any applicable
                    payment terms and conditions. We encourage you to learn about the practices of such third party. In
                    no event will Talking Yak be responsible for the actions or inactions of any third party payment
                    processor, including, but not limited to, system downtime or payment service outages.</Trans>
                </div>
              </div>
              <div className="sub-subtitle-description">
                <h4 className="subtitle-wraped"><Trans>11. Refund Policy</Trans></h4>
                <div className="descptionwraper">
                  <Trans>In the event that Talking Yak suspends or terminates your use of the Service or these Terms and
                    Conditions or you close your account voluntarily, you understand and agree that you will receive no
                    refund or exchange of any kind, any Content or data associated with your use of the Service, or for
                    anything else.</Trans>
                </div>
              </div>
              <div className="sub-subtitle-description">
                <h4 className="subtitle-wraped">12. NO REPRESENTATIONS OR WARRANTIES BY TALKING YAK</h4>
                <div className="descptionwraper">
                  THE SERVICE, INCLUDING ALL IMAGES, AUDIO FILES AND OTHER CONTENT THEREIN, AND ANY OTHER INFORMATION,
                  PROPERTY AND RIGHTS GRANTED OR PROVIDED TO YOU BY TALKING YAK ARE PROVIDED TO YOU ON AN “AS IS” BASIS.
                  TALKING YAK AND ITS SUPPLIERS MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND WITH RESPECT TO THE
                  SERVICE, EITHER EXPRESS OR IMPLIED, AND ALL SUCH REPRESENTATIONS AND WARRANTIES, INCLUDING WARRANTIES
                  OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT, ARE EXPRESSLY DISCLAIMED.
                  WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, TALKING YAK DOES NOT MAKE ANY REPRESENTATION OR
                  WARRANTY OF ANY KIND RELATING TO ACCURACY, SERVICE AVAILABILITY, COMPLETENESS, INFORMATIONAL CONTENT,
                  ERROR-FREE OPERATION, RESULTS TO BE OBTAINED FROM USE, OR NON-INFRINGEMENT. ACCESS AND USE OF THE
                  SERVICE MAY BE UNAVAILABLE DURING PERIODS OF PEAK DEMAND, SYSTEM UPGRADES, MALFUNCTIONS OR SCHEDULED
                  OR UNSCHEDULED MAINTENANCE OR FOR OTHER REASONS. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF
                  IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.
                </div>
              </div>
              <div className="sub-subtitle-description">
                <h4 className="subtitle-wraped">13. LIMITATION ON TYPES OF DAMAGES/LIMITATION OF LIABILITY</h4>
                <div className="descptionwraper">
                  IN NO EVENT WILL TALKING YAK BE LIABLE TO YOU OR ANY THIRD PARTY CLAIMING THROUGH YOU (WHETHER BASED
                  IN CONTRACT, TORT, STRICT LIABILITY OR OTHER THEORY) FOR INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL
                  OR EXEMPLARY DAMAGES ARISING OUT OF OR RELATING TO THE ACCESS OR USE OF, OR THE INABILITY TO ACCESS OR
                  USE, THE SERVICE OR ANY PORTION THEREOF, INCLUDING BUT NOT LIMITED TO THE LOSS OF USE OF THE SERVICE,
                  INACCURATE RESULTS, LOSS OF PROFITS, BUSINESS INTERRUPTION, OR DAMAGES STEMMING FROM LOSS OR
                  CORRUPTION OF DATA OR DATA BEING RENDERED INACCURATE, THE COST OF RECOVERING ANY DATA, THE COST OF
                  SUBSTITUTE SERVICES OR CLAIMS BY THIRD PARTIES FOR ANY DAMAGE TO COMPUTERS, SOFTWARE, MODEMS,
                  TELEPHONES OR OTHER PROPERTY, EVEN IF TALKING YAK HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                  TALKING YAK’S LIABILITY TO YOU OR ANY THIRD PARTY CLAIMING THROUGH YOU FOR ANY CAUSE WHATSOEVER, AND
                  REGARDLESS OF THE FORM OF THE ACTION, IS LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO TALKING YAK FOR
                  THE SERVICE IN THE 12 MONTHS PRIOR TO THE INITIAL ACTION GIVING RISE TO LIABILITY. THIS IS AN
                  AGGREGATE LIMIT. THE EXISTENCE OF MORE THAN ONE CLAIM HEREUNDER WILL NOT INCREASE THIS LIMIT.
                </div>
              </div>
              <div className="sub-subtitle-description">
                <h4 className="subtitle-wraped">14. Termination</h4>
                <div className="descptionwraper">
                  Talking Yak may terminate your access and use of the Service immediately at any time, for any reason,
                  and at such time you will have no further right to use the Service. You may terminate your Talking Yak
                  account at any time by following the instructions available through the Service. The provisions of
                  these Terms and Conditions relating to the protection and enforcement of Talking Yak’s proprietary
                  rights, your representations and warranties, disclaimer of representations and warranties, release and
                  indemnities, limitations of liability and types of damages, ownership of data and information,
                  governing law and venue, and miscellaneous provisions shall survive any such termination.
                </div>
              </div>
              <div className="sub-subtitle-description">
                <h4 className="subtitle-wraped">15. Proprietary Rights in Service Content and Activity Materials</h4>
                <div className="descptionwraper">
                  All content available through the Service, including designs, text, graphics, images, information,
                  software, audio and other files, and their selection and arrangement (the “Service Content”), are the
                  proprietary property of Talking Yak or its licensors. No Service Content may be modified, copied,
                  distributed, framed, reproduced, republished, downloaded, scraped, displayed, posted, transmitted, or
                  sold in any form or by any means, in whole or in part, other than as expressly permitted in these
                  Terms and Conditions. You may not use any data mining, robots, scraping or similar data gathering or
                  extraction methods to obtain Service Content. As between you and Talking Yak, all data, information
                  and materials generated from your access and use of the educational activities made available on or
                  through the Service, including translated content generated by you (collectively, the “Activity
                  Materials”), shall be exclusively owned by Talking Yak, and you shall not have any right to use such
                  Activity Materials except as expressly authorized by these Terms and Conditions. By using the Service,
                  you hereby assign to Talking Yak any and all rights, title and interest, including any intellectual
                  property rights or proprietary rights, in the Activity Materials. All rights of Talking Yak or its
                  licensors that are not expressly granted in these Terms and Conditions are reserved to Talking Yak and
                  its licensors.
                </div>
              </div>
              <div className="sub-subtitle-description">
                <h4 className="subtitle-wraped">16. Trademarks</h4>
                <div className="descptionwraper">
                  “Talking Yak”, and all other trademarks, service marks, graphics and logos used in connection with the
                  Service are trademarks or service marks of Talking Yak or their respective owners, and certain of them
                  are registered with the United States Patent and Trademark Office. Access and use of the Service does
                  not grant or provide you with the right or license to reproduce or otherwise use the Talking Yak name
                  or any Talking Yak or third-party trademarks, service marks, graphics or logos.
                </div>
              </div>
              <div className="sub-subtitle-description">
                <h4 className="subtitle-wraped">17. Privacy</h4>
                <div className="descptionwraper">
                  Use of the Service is also governed by our Privacy Policy, a copy of which is located at
                  https://talkingyak.com/ta/privacy-terms. By using the Service, you consent to the terms of the Privacy Policy.
                </div>
              </div>
              <div className="sub-subtitle-description">
                <h4 className="subtitle-wraped">18. Notice for Claims of Copyright Violations and Agent for Notice</h4>
                <div className="descptionwraper">
                  If you are a copyright owner and have a good faith belief that any material available through the
                  Service infringes upon your copyrights, you may submit a copyright infringement notification to
                  Talking Yak pursuant to the Digital Millennium Copyright Act by providing us with the following
                  information in writing:
                  <ul>
                    <li>an electronic or physical signature of the copyright owner or the person authorized to act on
                      behalf of the owner of the copyright interest;
                    </li>
                    <li>a description of the copyrighted work that you claim has been infringed;</li>
                    <li>a description of where the material that you claim is infringing is located on the Service, with
                      enough detail that we may find it on the Service;
                    </li>
                    <li>your address, telephone number, and email address;</li>
                    <li>a statement by you that you have a good faith belief that the disputed use is not authorized by
                      the copyright owner, its agent, or the law; and
                    </li>
                    <li>a statement by you, made under penalty of perjury, that the above information in your notice is
                      accurate and that you are the copyright owner or are authorized to act on the copyright owner’s
                      behalf.
                    </li>
                    <li>Please consult your legal counsel for further details or see 17 U.S.C. §512©(3). Talking Yak’s
                      Agent for Notice of claims of copyright infringement can be reached, by mail, as follows:
                    </li>
                  </ul>
                  <div>Talking Yak English Learning Pvt Ltd<br/> WeWork Prestige Central,<br/> 36 Infantry Rd, Tasker Town,<br/>
                    Bangalore, Karnataka 560001
                  </div>
                </div>
              </div>
              <div className="sub-subtitle-description">
                <h4 className="subtitle-wraped">19. Governing Law and Arbitration; No Class Action</h4>
                <div className="descptionwraper">
                  <p>These Terms and Conditions, its subject matter and Talking Yak’s and your respective rights under
                    these Terms and Conditions, as well as any claim, cause of action or dispute (“claim”) arising out
                    of or related to these Terms and Conditions, shall be governed by and construed under the laws of
                    the Commonwealth of Delaware, United States of America, excluding the conflict of law provisions of
                    that or any other jurisdiction, regardless of your country of origin or where you access the
                    Service. ANY DISPUTE OR CLAIM RELATING IN ANY WAY TO THESE TERMS AND CONDITIONS OR THE SERVICE WILL
                    BE RESOLVED BY BINDING ARBITRATION, RATHER THAN IN COURT, except for Talking Yak’s right to seek
                    injunctive relief as set forth below. Unless otherwise expressly required by applicable law, each
                    party shall bear its own attorneys’ fees without regard to which party is deemed the prevailing
                    party in the arbitration proceeding.</p><p> If you do not want to arbitrate disputes with Talking
                  Yak and you are an individual, you may opt out of this arbitration agreement by sending an email to
                  legal@Talking Yak.com within 30 days of the day you first access or use the Service.</p><p> If you
                  intend to seek arbitration you must first send written notice to Talking Yak’s Administration Office
                  of your intent to arbitrate (“Notice”). The Notice to Talking Yak should be sent by any of the
                  following means: (i) electronic mail to legal@Talking Yak.com; or (ii) sending the Notice by U.S.
                  Postal Service certified mail to:</p><p> Talking Yak English Learning Pvt Ltd <br/>WeWork Prestige Central,<br/> 36 Infantry Rd, Tasker Town,<br/> Bangalore, Karnataka 560001</p> <p>The Notice must (x) describe the
                  nature and basis of the claim or dispute; and (y) set forth the specific relief sought; and (z) set
                  forth your name, address and contact information. If we intend to seek arbitration against you, we
                  will send any notice of dispute to you at the contact information we have for you.</p><p> The
                  arbitration will be conducted before a neutral single arbitrator in the Commonwealth of Delaware,
                  whose decision will be final and binding, and the arbitral proceedings will be governed by the
                  American Arbitration Association (“AAA”) under its AAA Commercial Arbitration Rules, Consumer Due
                  Process Protocol, and Supplementary Procedures for Resolution of Consumer Related Disputes, as
                  modified by these Terms and Conditions. The AAA’s rules are available at www.adr.org or by calling
                  1-800-778-7879. All issues are for the arbitrator to decide, including the scope of this arbitration
                  clause, but the arbitrator is bound by the terms of these Terms and Conditions. If you initiate
                  arbitration, your arbitration fees will be limited to the filing fee set forth in the AAA’s Consumer
                  Arbitration Rules. We will reimburse all other AAA filing, administration and arbitrator fees paid by
                  you, unless the arbitrator determines that the arbitration was frivolous or brought for an improper
                  purpose, in which case the payment of all such fees shall be governed by the AAA rules. The
                  arbitration will be conducted in the English language. Judgment on the award rendered by the
                  arbitrator may be entered in any court of competent jurisdiction. For any claim where the potential
                  award is reasonably likely to be $10,000 or less, either you or Talking Yak may elect to have the
                  dispute resolved through non-appearance-based arbitration.</p><p> To the fullest extent permitted by
                  applicable law, YOU AND TALKING YAK EACH AGREE THAT ANY DISPUTE RESOLUTION PROCEEDING WILL BE
                  CONDUCTED ONLY ON AN INDIVIDUAL BASIS AND NOT IN A CLASS, CONSOLIDATED OR REPRESENTATIVE ACTION. If
                  for any reason a claim proceeds in court rather than in arbitration, YOU AND TALKING YAK EACH WAIVE
                  ANY RIGHT TO A JURY TRIAL. If a court of competent jurisdiction finds the foregoing arbitration
                  provisions invalid or inapplicable, you and Talking Yak agree that all claims arising out of or
                  related to these Terms and Conditions must be resolved exclusively by a state or federal court located
                  in the Commonwealth of Delaware, and you and Talking Yak each agree to submit to the exercise of
                  personal jurisdiction of such courts for the purpose of litigating all such claims. Notwithstanding
                  the above, you agree that Talking Yak shall still be allowed to apply for and obtain injunctive
                  remedies (or an equivalent type of urgent legal relief) in any jurisdiction.</p>
                </div>
              </div>
              <div className="sub-subtitle-description">
                <h4 className="subtitle-wraped">20. Language</h4>
                <div className="descptionwraper">
                  This agreement was originally written in English (US). To the extent any translated version of this
                  agreement conflicts with the English version, the English version controls.
                </div>
              </div>
              <div className="sub-subtitle-description">
                <h4 className="subtitle-wraped">21. Miscellaneous</h4>
                <div className="descptionwraper">
                  These Terms and Conditions constitute the entire agreement between Talking Yak and you concerning the
                  subject matter hereof. In the event that any of the Terms and Conditions are held by a court or other
                  tribunal of competent jurisdiction to be unenforceable, such provisions shall be limited or eliminated
                  to the minimum extent necessary so that these Terms and Conditions shall otherwise remain in full
                  force and effect. A waiver by Talking Yak or you of any provision of these Terms and Conditions or any
                  breach thereof, in any one instance, will not waive such term or condition or any subsequent breach
                  thereof. Talking Yak may assign its rights or obligations under these Terms and Conditions without
                  condition. These Terms and Conditions will be binding upon and will inure to the benefit of Talking
                  Yak and you, and Talking Yak’s and your respective successors and permitted assigns.
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export const query = graphql`
    {
        locales: allLocale {
            edges {
                node {
                    language
                    ns
                    data
                }
            }
        }
    }
`
